import React, { useEffect, useState } from "react";
import L, { Marker } from "leaflet";
import "leaflet/dist/leaflet.css";
import { PGET } from "helpers/api";

const MapComponent = () => {
	const [map, setMap] = useState(null);
	const [markers, setMarkers] = useState([]);
	const [Clients, setClients] = useState([]);

	useEffect(() => {
		const initializeMap = () => {
			const newMap = L.map("map").setView([41.2995, 69.2401], 6);
			L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
				attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
			}).addTo(newMap);
			setMap(newMap);
		};

		initializeMap();
	}, []);

	useEffect(() => {
		const getData = async () => {
			const response = await PGET("/services/admin/api/pos-get-all", {}, { loader: true });
			const data = response.data || [];
			setClients(response.data)
			if (map) {
				markers.forEach((marker) => marker.remove());

				const customIcon = L.icon({
					iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
					iconSize: [25, 41],
					iconAnchor: [12, 41],
					popupAnchor: [1, -34],
					shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
					shadowSize: [41, 41],
				});

				const newMarkers = data.map((item) => {
					let latitude, longitude;
					const addressParts = item.address.split(",").map(part => part.trim());
					if (!isNaN(parseFloat(addressParts[0])) && !isNaN(parseFloat(addressParts[1]))) {
						[latitude, longitude] = addressParts.map(Number);
					} else {
						latitude = item.gpsPointX;
						longitude = item.gpsPointY;
					}
		
					if (latitude && longitude) {
						L.marker([latitude, longitude], { icon: customIcon })
							.addTo(map)
							.bindPopup(
								`<b>Do'kon nomi:</b> ${item.name}<br/>` +
								`<b>Manzil:</b> ${item.address}<br/>` +
								`<b>Login:</b> ${item.login}<br/>` +
								`<b>Telefon:</b> ${item.phone}`
							);
					}

				}).filter(Boolean);

				setMarkers(newMarkers);
			}
		};

		getData();
	}, [map]);

	return (
		<div>
			<h2 className="my-2">Jami mijozlar: {Clients.length}</h2>
			<div style={{ width: "100%", borderRadius: "50px", backgroundColor: "white", height: "auto" }}>
				<div id="map" style={{ height: "500px", width: "100%", borderRadius: "15px" }}></div>
			</div>
		</div>
	);
};

export default MapComponent;