import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Select from 'react-select'

import Search from 'pages/Parts/Search'
import Pagination from 'pages/Parts/Pagination'

import { PGET, GET, PUT, POST } from 'helpers/api'
import { formatDate, formatMoney, formatDateWithTime } from 'helpers/helpers'
import { Controller } from "react-hook-form"

function Debtors() {
  const URL = '/services/admin/api/pos-pageList'
  const { t } = useTranslation()

  const paymentTypes = [
    { 'id': 1, 'name': t('CASH') },
    { 'id': 2, 'name': t('TRANSFER') },
    { 'id': 3, 'name': t('MONEY_TRANSFER') },
  ]
  const tariffs = [
    { "id": 1, "name": "START", "value": 150000 },
    { "id": 2, "name": "STANDART", "value": 300000 },
    { "id": 3, "name": "PREMIUM", "value": 700000 }
  ]

  const Filters = [
    { id: 0, status: t('ALL') },
    { id: 1, status: t('TIME_PASSED') }, // muddati o'tib ketgan hammasi uchun
    { id: 2, status: t('DAYS_LEFT') } // aktiv bo'lmaganlar uchun
  ]

  const settings = useSelector(state => state.settings)
  const [pageCount, setPageCount] = useState(0)
  const [modalPayment, setModalPayment] = useState(false)
  const [modalPaymentHistory, setModalPaymentHistory] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [data, setData] = useState([])
  const [payments, setPayments] = useState([])
  // const [Filters, setFilters] = useState([

  // ])

  const [expType, setExpType] = useState(0)

  function selectItem(item) {
    var itemCopy = { ...item }
    itemCopy.posId = itemCopy.id
    itemCopy.amountIn = ""
    itemCopy.paymentMethod = 1
    itemCopy.note = ""
    setSelectedItem(itemCopy)
  }

  async function payment(e) {
    e.preventDefault();

    var selectedItemCopy = { ...selectedItem }

    var sendData = {}
    sendData.posId = selectedItemCopy.posId
    sendData.amountIn = selectedItemCopy.amountIn
    sendData.paymentMethod = selectedItemCopy.paymentMethod
    sendData.note = selectedItemCopy.note

    var response = await POST('/services/admin/api/pos-payment', sendData, { loader: true })

    if (response) {
      showPaymentModal(false)
      getData()
    }
  }

  function showPaymentModal(bool) {
    if (!bool) {
      var selectedItemCopy = { ...selectedItem }
      selectedItemCopy.posId = null
      selectedItemCopy.amountIn = ""
      selectedItemCopy.paymentMethod = 2
      selectedItemCopy.note = ''
    }

    if (!selectedItem?.id) {
      toast.warning(t('NO_POS_SELECTED'))
      return
    }

    setModalPayment(bool)
  }

  async function showPaymentHistoryModal(bool, posId) {
    if (!bool) {
      setModalPaymentHistory(bool)
      return;
    }
    const response = await GET('/services/admin/api/pos-payment-history/' + posId, {}, { loader: true })
    setPayments(response)
    setModalPaymentHistory(bool)
  }

  async function handleDocumentsInCreate(e, index) {
    var dataCopy = [...data]
    dataCopy[index][e.target.name] = e.target.checked
    setData(dataCopy)
    var sendData = {
      id: dataCopy[index]['id'],
      activated: e.target.checked,
    }
    await PUT('/services/admin/api/pos-activate', sendData, { loader: true })
  }

  async function getData() {
    const response = await PGET('/services/admin/api/pos-pageListDebt', { expType: expType }, { loader: true })
    if (response) {
      setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
      setData(response.data)
    }
  }

  async function paginate(data) {
    const response = await PGET('/services/admin/api/pos-pageListDebt', { expType: expType, page: data.selected, size: settings.size }, { loader: true })
    setData(response.data);
  }

  async function search(data, searchValue) {
    setData(data);
    if (searchValue.length > 0) {
      setPageCount(0)
    }
  }

  const applyFilter = (type) => {
    setExpType(type); // Update the state
  };

  // Use useEffect to react to expType changes
  useEffect(() => {
    if (expType !== null) {
      getData();
    }

    // Berilgan sanalar
    const paidFrom = "05.11.2024";
    const paidUntil = "05.12.2024";

    // Sanalarni Date obyektiga aylantirish (to'g'ri formatda)
    const startDate = new Date(paidFrom.split(".").reverse().join("-"));
    const endDate = new Date(paidUntil.split(".").reverse().join("-"));

    // Farqni hisoblash (millisekundalarda) va kunlarga aylantirish
    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    console.log(`Ikki sana orasidagi farq: ${differenceInDays} kun`);

  }, [expType]);

  return (
    <>
      <div className="page-header d-flex mb-2">
        <h4 className="vertical-center">
          {t('DEBT')}
        </h4>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="d-flex mb-3">
            <div className="me-2">
              <Search url={URL} search={search} getData={getData}></Search>
            </div>
            <div className="form-group">
              {/* <label>{t('MANAGER')}</label> */}
              <Select className="w-250px"
                // {...field}
                options={Filters}
                // value={Filters.find(option => option?.id === getValues('managerId'))}
                placeholder="Filter"
                onChange={(selectedOption) => applyFilter(selectedOption.id)}
                noOptionsMessage={() => t('LIST_IS_EMPTY')}
                getOptionLabel={(option) => option?.status}
                getOptionValue={(option) => option?.id}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>№</th>
                  <th>{t('ID')}</th>
                  <th>{t('OWNER')}</th>
                  <th>{t('SALE_POINT_NAME')}</th>
                  <th>{t('PHONE')}</th>
                  <th className="text-center">{t('DATE_OF_START')}</th>
                  <th className="text-center">{t('DATE_OF_END')}</th>
                  <th className="text-center">{t('DAY')}</th>
                  <th className="text-center">{t('BALANCE')}</th>
                  <th className="text-center">{t('TARIFF')}</th>
                  <th className="text-center">{t('TARIFF_AMOUNT')}</th>
                  <th className="text-center">{t('CHEQUES')}</th>
                  <th className="text-center">{t('ACTIVATED')}</th>
                  {/* <th className="text-center">{t('ACTION')}</th> */}
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? data.map((item, index) => {
                  const paidUntil = item.paidUntil;

                  // Sanani DD.MM.YYYY formatidan YYYY-MM-DD formatiga o'tkazamiz
                  const endDate = paidUntil ? new Date(paidUntil.split(".").reverse().join("-")) : null;

                  let daysUntilPaid = null;
                  if (endDate && !isNaN(endDate.getTime())) {
                    const now = new Date();
                    now.setHours(0, 0, 0, 0); // Bugunning vaqt qismini nolga o‘rnatamiz
                    endDate.setHours(0, 0, 0, 0); // End Date vaqt qismini nolga o‘rnatamiz

                    const differenceInMilliseconds = endDate.getTime() - now.getTime();
                    daysUntilPaid = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
                  }

                  return (
                    <tr className={'tr-middle ' + ((selectedItem && selectedItem.id === item.id) ? 'table-tr-active' : '')}
                      key={index} onClick={() => selectItem(item)}>
                      <td>{index + 1}</td>
                      <td>{item.id}</td>
                      <td>{item.ownerLogin}</td>
                      <td>{item.name}</td>
                      <td>{item.phone}</td>
                      <td className="text-center ">{item.paidFrom}</td>
                      <td className="text-center ">{item.paidUntil}</td>
                      <td className={`text-center ${daysUntilPaid !== null && daysUntilPaid < 0 ? 'text-danger' : ''}`}>
                        {daysUntilPaid !== null ? daysUntilPaid : "-"}
                      </td>
                      <td className={`text-center bold text-${(item.tariff == 0 || item.balance < item.tariff) ? 'danger' : 'success'}`}>{formatMoney(item.balance)}</td>
                      <td className="text-center">{tariffs.find(element => element.id === item.tariffId)?.name}</td>
                      <td className={`text-center ${item.tariff == 0 && 'text-danger'}`}>{formatMoney(item.tariff)}</td>
                      <td>{item.cheques}</td>
                      <td className="text-center">
                        <div className="d-flex justify-content-center">
                          <div className="form-check form-switch form-switch-lg">
                            <input type="checkbox" className="form-check-input"
                              name="activated"
                              checked={item.activated}
                              onChange={(e) => handleDocumentsInCreate(e, index)} />
                          </div>
                        </div>
                      </td>
                      {/* <td>
											<div className="d-flex justify-content-center">
												<div className="d-flex justify-content-center me-2">
													<Link className="table-action-button table-action-primary-button"
														to={'/pos-update/' + item.id}>
														<i className="uil-edit-alt"></i>
													</Link>
												</div>
											</div>
										</td> */}
                    </tr>
                  )
                })
                  : <tr className="text-center" colSpan="10">
                    <td>No data found</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>

          <Pagination pageCount={pageCount} paginate={paginate}></Pagination>
        </div>
      </div>

      {/* PAYMENT */}
      <Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('PAY')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between flex-wrap mb-3">
            <span>{t('OWNER')}</span>
            <span>{selectedItem.ownerLogin}</span>
          </div>
          <div className="d-flex justify-content-between flex-wrap mb-3">
            <span>{t('POS')}</span>
            <span>{selectedItem.name}</span>
          </div>
          <form onSubmit={payment} autoComplete="off">
            <div className="form-group">
              <label>{t('PAYMENT_METHOD')}</label>
              <Select
                options={paymentTypes}
                value={paymentTypes.find(option => option.id === selectedItem.paymentMethod)}
                onChange={(option) => setSelectedItem({ ...selectedItem, 'paymentMethod': option.id })}
                placeholder=""
                noOptionsMessage={() => t('LIST_IS_EMPTY')}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </div>
            <div className="form-group">
              <label>{t('AMOUNT')}<span className="required-mark">*</span></label>
              <input type="number" className="form-control" name="amountIn" autoFocus
                onChange={(e) => setSelectedItem({ ...selectedItem, 'amountIn': e.target.value })} />
            </div>
            <div className="form-group">
              <label>{t('NOTE')}</label>
              <input type="text" className="form-control" name="note"
                onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
            </div>
            <div className="d-flex w-100 mt-3">
              <button type="button" className="btn btn-outline-warning w-100 me-2"
                onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
              <button type="submit" className="btn btn-success w-100" disabled={!selectedItem.amountIn}>
                {t('SAVE')}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* PAYMENT */}

      {/* PAYMENT HISTORY */}
      <Modal show={modalPaymentHistory} animation={false} centered size="lg"
        dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentHistoryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('HISTORY')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>{t('TYPE')}</th>
                  <th>{t('NOTE')}</th>
                  <th>Создал</th>
                  <th>Оплата</th>
                  <th className="text-center">{t('DATE')}</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((item, index) => (
                  <tr key={index}>
                    <td>{item.paymentTypeName}</td>
                    <td>{item.note}</td>
                    <td>{item.createdBy}</td>
                    <td>
                      <div className="text-nowrap">
                        {formatMoney(item.amountIn)}
                      </div>
                    </td>
                    <td>
                      <div className="text-nowrap">
                        {formatDateWithTime(item.createdDate)}
                      </div>
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      {/* PAYMENT HISTORY */}
    </>
  )
}

export default Debtors
