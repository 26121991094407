import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from '../admin/clients/validation'
import { regexPhoneNumber, regexNumeric } from 'helpers/helpers'
import { PGET, POST } from "helpers/api";
import Select from "react-select";

function Register() {
	const { t } = useTranslation();
	const { register, handleSubmit, formState, control, setValue, getValues, reset, trigger } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: { firstName: "", lastName: "", phone: "", userLogin: "", password: "", managerId: "" }
	});
	const [validData, setValidData] = useState({ 'isValid': 3 })
	const { errors } = formState
	const [managerData, setManagerData] = useState([])
	const [activeStep, setActiveStep] = useState(0);
	const [isAnimating, setIsAnimating] = useState(false);

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		var sendPosData = { managerId: sendData.managerId, ownerLogin: sendData.userLogin }
		sendData.phone = '998' + regexNumeric(sendData.phone)

		console.log("SendData", sendData);

		if (validData.isValid === 5) {
			return
		}

		var response;
		response = await POST('/services/uaa/api/owner', sendData, { loader: true })

		await POST('/services/admin/api/pos-addManager', sendPosData, { loader: true })

		if (response) {
			reset()
			setActiveStep(0)
		}
	}

	function validatePassword(password) {
		var regexm = new RegExp("^.*[a-zA-Z].*$")
		var regexm2 = new RegExp("^.*[0-9].*$")

		if (password === "") {
			setValidData({ ...validData, 'isValid': 3 })
		}
		if (regexm.test(password) && regexm2.test(password)) {
			setValidData({ ...validData, 'password': password, 'isValid': 4 })
		} else {
			setValidData({ ...validData, 'password': password, 'isValid': 5 })
		}
	}

	async function getManagerData() {
		const response = await PGET('/services/uaa/api/manager-allList', {}, { loader: true })
		if (response) {
			setManagerData(response.data)
		}
	}

	useEffect(() => {
		getManagerData()
	}, [])

	const category = [
		{ id: 1, name: "Xoz mag" },
		{ id: 2, name: "Aksessuarlar" },
		{ id: 3, name: "O'yinchoqlar" },
		{ id: 4, name: "Oziq-ovqat" },
		{ id: 5, name: "Texnika" },
		{ id: 6, name: "Avto zapchast" },
		{ id: 7, name: "Qurilish mollar" },
		{ id: 8, name: "Mebel" },
		{ id: 9, name: "Parfumeriya" },
		{ id: 10, name: "Avtoximiya" },
		{ id: 11, name: "Kanstovar" },
		{ id: 12, name: "Gigiyenik maxsulotlar" },
		{ id: 13, name: "Bolalar kiyimlari" },
		{ id: 14, name: "Boshqalar" }
	];

	const nextStep = async () => {
		// Birinchi forma qismini tekshirish
		const isValid = await trigger(['firstName', 'phone']);
		if (isValid) {
			setIsAnimating(true);
			setTimeout(() => {
				setActiveStep(1);
				setIsAnimating(false);
			}, 500);
		}
	};

	const prevStep = () => {
		setIsAnimating(true);
		setTimeout(() => {
			setActiveStep(0);
			setIsAnimating(false);
		}, 500);
	};

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 ">
								<div className="bg-white rounded-2 p-3 auth-card">
									<div className="text-center my-2">
										<h3 className="">
											{t('REGISTER')}
										</h3>
										<div className="custom-stepper mt-4 mb-4">
											<div className="step-container">
												<div className={`step ${activeStep >= 0 ? 'active' : ''}`}>
													<div className="step-number">1</div>
													<div className="step-title">{t('Shaxsiy ma\'lumotlar')}</div>
												</div>
												<div className="step-line"></div>
												<div className={`step ${activeStep >= 1 ? 'active' : ''}`}>
													<div className="step-number">2</div>
													<div className="step-title">{t('Akaunt ma\'lumotlari')}</div>
												</div>
											</div>
										</div>
									</div>
									<form className="form-horizontal" onSubmit={handleSubmit(createUpdate)} autoComplete="off">
										<div className={`form-step ${isAnimating ? 'fade-out' : 'fade-in'}`} style={{ display: activeStep === 0 ? 'block' : 'none' }}>
											<div className="form-group">
												<label>{t('NAME')} {t('LAST_NAME')}<span className="required-mark">*</span></label>
												<input type="text" className="form-control" name="firstName" {...register('firstName')} />
												<span className="text-danger text-sm">{errors.firstName?.message}</span>
											</div>
											<div className="form-group">
												<label>{t('SALE_POINT_NAME')}<span className="required-mark">*</span></label>
												<input type="text" className="form-control" name="salePointName" {...register('salePointName')} />
												<span className="text-danger text-sm">{errors.salePointName?.message}</span>
											</div>
											<div className="form-group">
												<label>{t('Yuridik nomi')}<span className="required-mark">*</span></label>
												<input type="text" className="form-control" name="legalName" {...register('legalName')} />
												<span className="text-danger text-sm">{errors.legalName?.message}</span>
											</div>
											<div className="form-group">
												<label>{t('KIND_OF_ACTIVITY')}</label>
												<Controller
													name="activityType"
													control={control}
													render={({ field }) =>
														<Select
															{...field}
															options={category}
															placeholder=""
															onChange={(selectedOption) => field.onChange(selectedOption.id)}
															noOptionsMessage={() => t('LIST_IS_EMPTY')}
															getOptionLabel={(option) => option?.name}
															getOptionValue={(option) => option?.id}
														/>}
												/>
											</div>
											<div className="form-group">
												<label>{t('PHONE')}</label>
												<div className="input-group">
													<span className="input-group-text">998</span>
													<input type="number" className="form-control" name="phone" {...register('phone')} />
												</div>
												<span className="text-danger text-sm">{errors.phone?.message}</span>
											</div>

											<div className="d-flex w-100 mt-4">
												<button type="button" className="btn btn-success w-100" onClick={nextStep}>{t('NEXT')}</button>
											</div>
										</div>

										<div className={`form-step ${isAnimating ? 'fade-out' : 'fade-in'}`} style={{ display: activeStep === 1 ? 'block' : 'none' }}>
											<div className="form-group">
												<label>{t('LOGIN')}<span className="required-mark">*</span></label>
												<input type="text" className="form-control" name="userLogin" {...register('userLogin')} />
												<span className="text-danger text-sm">{errors.userLogin?.message}</span>
											</div>
											<div className="form-group">
												<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
												<input type="text" className="form-control" name="password"
													{...register('password', { required: true })}
													onChange={(e) => {
														validatePassword(e.target.value)
													}}
												/>
												<span className="text-danger text-sm">{errors.password?.type === 'required' && t('required')}</span>
												{validData.isValid === 5 &&
													<div className="text-danger text-sm">Пароль должен содержать буквы и цифры</div>
												}
											</div>
											<div className="form-group">
												<label>{t('MANAGER')}</label>
												<Controller
													name="managerId"
													control={control}
													render={({ field }) =>
														<Select
															{...field}
															options={managerData}
															value={managerData.find(option => option?.id === getValues('managerId'))}
															placeholder=""
															onChange={(selectedOption) => field.onChange(selectedOption.id)}
															noOptionsMessage={() => t('LIST_IS_EMPTY')}
															getOptionLabel={(option) => option?.firstName + ", " + option?.lastName}
															getOptionValue={(option) => option?.id}
														/>}
												/>
												<span className="text-danger text-sm">{errors.regionId}</span>
											</div>

											<div className="d-flex w-100 mt-4">
												<button type="button" className="btn btn-outline-secondary w-50 me-2" onClick={prevStep}>{t('BACK')}</button>
												<button type="submit" className="btn btn-success w-50">{t('SEND')}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>

			<style jsx>{`
				.form-step {
					transition: opacity 0.5s ease;
				}
				.fade-in {
					opacity: 1;
				}
				.fade-out {
					opacity: 0;
				}
				.custom-stepper {
					margin-bottom: 20px;
				}
				.step-container {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.step {
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;
					z-index: 1;
				}
				.step-number {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background-color: #e0e0e0;
					color: #757575;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: bold;
					margin-bottom: 5px;
					transition: all 0.3s ease;
				}
				.step.active .step-number {
					background-color: #2CA67A;
					color: white;
				}
				.step-title {
					font-size: 12px;
					color: #757575;
					transition: all 0.3s ease;
				}
				.step.active .step-title {
					color: #2CA67A;
					font-weight: bold;
				}
				.step-line {
					width: 100%;
					max-width: 200px;
					height: 3px;
					background-color: #e0e0e0;
					margin: 0 10px;
					top: -15px;
					transition: all 0.3s ease;
				}
				 .step-line,
				 {
					background-color: #2CA67A;
				}
			`}</style>
		</>
	)
}

export default Register;