import React, { useEffect, useState } from "react"
import { Link, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GoogleMapReact from 'google-map-react';
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { GET, POST, PUT, FILE } from 'helpers/api'

function CreateUpdate() {
	const { t } = useTranslation();
	const { id, ownerLogin } = useParams();
	const history = useHistory();

	

	const { register, handleSubmit, formState, control, setValue, getValues, watch } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			phone: "",
			regionId: 10,
			cityId: null,
			gpsPointX: 41.311081,
			gpsPointY: 69.240562,
			imageUrl: "",
			posTypeList: [],
			cashierList: [],
			merchandiserList: [],
			saleMinus: false,
			defaultCurrency: 1,
			tariffId: 1,
			hidePriceIn: true,
			loyaltyApi: "",
			balance: "",
			tariff: "",
			ownerLogin: '',
			ofd: false,
			activated: false,
		}
	});
	const { errors } = formState
	const cityId = watch('cityId')

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const tariffs = [
		{ "id": 1, "name": "START", "value": 100000 },
		{ "id": 2, "name": "STANDART", "value": 150000 },
		{ "id": 3, "name": "PREMIUM", "value": 250000 }
	]

	const [method, setMethod] = useState('create')
	const [regions] = useState([{ "id": 1, "name": "Андижанская область" }, { "id": 2, "name": "Бухарская область" }, { "id": 3, "name": "Джизакская область" }, { "id": 4, "name": "Кашкадарьинская область" }, { "id": 5, "name": "Навоийская область" }, { "id": 6, "name": "Наманганская область" }, { "id": 7, "name": "Самаркандская область" }, { "id": 8, "name": "Сурхандарьинская область" }, { "id": 9, "name": "Сырдарьинская область" }, { "id": 10, "name": "город Ташкент" }, { "id": 11, "name": "Ташкентская область" }, { "id": 12, "name": "Ферганская область" }, { "id": 13, "name": "Хорезмская область" }, { "id": 14, "name": "Респ. Каракалпакстан" }])
	const [cities, setCities] = useState([])
	const [posTypeList, setPosTypeList] = useState([])
	const [cashierList, setCashierList] = useState([])
	//const [merchandiserList, setMerchandiserList] = useState([])
	const [data, setData] = useState({
		gpsPointX: 41.311081,
		gpsPointY: 69.240562,
		imageUrl: '',
	})

	function selectLocation(t) {
		setData({ ...data, gpsPointX: t.lat, gpsPointY: t.lng })
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/admin/api/upload/image', formData);
		setValue('imageUrl', response.url)
		setData({ ...data, 'imageUrl': response.url })
	}

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		sendData.gpsPointX = data.gpsPointX
		sendData.gpsPointY = data.gpsPointY
		if (ownerLogin) {
			sendData.ownerLogin = ownerLogin
		}

		var response;
		if (sendData.id) {
			response = await PUT('/services/admin/api/pos', sendData, { loader: true })
			if (response) {
				history.push('/pos')
			}
		} else {
			response = await POST('/services/admin/api/pos', sendData, { loader: true })
			if (response) {
				history.push('/admin/clients')
			}
		}
	}

	async function selectRegion(option) {
		setValue('regionId', option.id)
		setValue('cityId', null)
		const response = await GET('/services/admin/api/city-helper/' + option.id)
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function getData() {
		const response = await GET('/services/admin/api/pos/' + id)

		const temporaryCities = await GET('/services/admin/api/city-helper/' + response.regionId)
		setCities(temporaryCities)

		//const temporaryMerchandiserList = await GET('/services/web/api/merchandiser-helper')
		//setMerchandiserList(temporaryMerchandiserList)

		const temporaryCashierList = await GET('/services/admin/api/cashier-helper/' + id)
		setCashierList(temporaryCashierList)
		console.log(response);
		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}
		setData({ ...data, 'imageUrl': response.imageUrl, 'gpsPointX': response.gpsPointX, 'gpsPointY': response.gpsPointY })
	}

	async function getcashierList() {
		console.log(id);

		const response = await GET('/services/admin/api/cashier-helper/' + id)
		setCashierList(response)
	}

	// async function getmerchandiserList() {
	// 	const response = await GET('/services/web/api/merchandiser-helper')
	// 	setMerchandiserList(response)
	// }

	async function getposTypeList() {
		const response = await GET('/services/admin/api/pos-type-helper')
		setPosTypeList(response)
	}

	async function getCities() {
		const response = await GET('/services/admin/api/city-helper/' + getValues('regionId'))
		if (response.length > 0) {
			setCities(response)
		}
	}

	useEffect(() => {
		if (id) {
			setMethod('update')
			getData()
		} else {
			getcashierList()
			//getmerchandiserList()
			getposTypeList()
			getCities()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const Marker = () => (
		<div>
			<div className='pin'></div>
			<div className='pulse'></div>
		</div>
	);

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('POS')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('EDIT')}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="row mb-5">
							<div className="col-md-2">
								<div className="con-upload">
									<div className="con-img-upload">
										{data.imageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file" onClick={() => setData({ ...data, 'imageUrl': '' })}>
													<i className="uil-times"></i>
												</button>
												<img src={'https://my.idokon.uz' + data.imageUrl}
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
											</div>
										}
										{!data.imageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={(e) => fileUpload(e)} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('SALE_POINT_NAME')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name" {...register('name')} />
									<span className="text-danger text-sm">{errors.name?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('TIN')}</label>
									<input type="number" className="form-control" name="tin" {...register('tin')} />
									<span className="text-danger text-sm">{errors.tin?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('PHONE')}<span className="required-mark">*</span></label>
									<input type="number" className="form-control" name="phone" {...register('phone')}
									/>
									<span className="text-danger text-sm">{errors.phone?.message}</span>
								</div>
							</div>
							<div className="col-md-3">
								<GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyDRnmjaTmZQmjt4sxKaNVW_DqOfp993Afc" }}
									defaultZoom={14}
									center={{ lat: data.gpsPointX, lng: data.gpsPointY }}
									onClick={selectLocation}>
									<Marker
										lat={data.gpsPointX}
										lng={data.gpsPointY}
									/>
								</GoogleMapReact>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>{t('ADDRESS')}</label>
									<input type="text" className="form-control" name="address" {...register('address')} />
									<span className="text-danger text-sm">{errors.address?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('REGION')}<span className="required-mark">*</span></label>
									<Controller
										name="regionId"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												options={regions}
												value={regions.find(option => option.id === getValues('regionId')) || ''}
												onChange={(option => selectRegion(option))}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
									<span className="text-danger text-sm">{errors.regionId}</span>
								</div>
								<div className="form-group">
									<label>{t('CITY')}<span className="required-mark">*</span></label>
									<Controller
										name="cityId"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												options={cities}
												value={cities.find(option => option.id === getValues('cityId')) || ''}
												onChange={(e) => setValue('cityId', e.id, { shouldValidate: true, shouldDirty: true })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
								</div>
								<span className="text-danger text-sm">{errors.cityId}</span>
							</div>
						</div>

						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<h5 className="fw-600">{t('ADDITIONALLY')}</h5>
									<hr />
								</div>
								<div className="row">
									<div className="col-md-12">
										{method === "create" &&
											<div className="form-group">
												<label>{t('ACTIVITIES')}</label>
												<Controller
													name="posTypeList"
													control={control}
													render={({ field }) =>
														<Select
															{...field}
															options={posTypeList}
															isMulti
															menuPosition='fixed'
															placeholder=""
															noOptionsMessage={() => t('LIST_IS_EMPTY')}
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
														/>}
												/>
											</div>
										}
										{/* <div className="form-group">
											<label>{t('MERCHANDISER')}</label>
											<Controller
												name="merchandiserList"
												control={control}
												render={({ field }) =>
													<Select
														{...field}
														options={merchandiserList}
														isMulti
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>}
											/>
										</div> */}
										<div className="form-group">
											<label>{t('CASHIERS')}</label>
											<Controller
												name="cashierList"
												control={control}
												render={({ field }) =>
													<Select
														{...field}
														options={cashierList}
														isMulti
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<h5 className="fw-600">{t('SETTINGS')}</h5>
									<hr />
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('CURRENCY')}</p>
									<div className="col-md-4 p-0">
										<Controller
											name="defaultCurrency"
											control={control}
											render={({ field }) =>
												<Select
													{...field}
													options={currencies}
													value={currencies.find(option => option.id === getValues('defaultCurrency'))}
													onChange={(e) => setValue('defaultCurrency', e.id)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>}
										/>
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('NEGATIVE_SALE')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="saleMinus" {...register('saleMinus')} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('HIDE_ADMISSION_PRICE')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="hidePriceIn" {...register('hidePriceIn')} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('LOYALTY')}</p>
									<div className="col-md-4 p-0">
										<input type="text" className="form-control" name="loyaltyApi" {...register('loyaltyApi')} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('ACTIVATED')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="activated" {...register('activated')} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">VIP</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="vipClient" {...register('vipClient')} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('TARIFF')}</p>
									<div className="col-md-4 p-0">
										<Controller
											name="tariffId"
											control={control}
											render={({ field }) =>
												<Select
													{...field}
													options={tariffs}
													value={tariffs.find(option => option.id === getValues('tariffId'))}
													onChange={(e) => {
														setValue('tariffId', e.id)
														setValue('tariff', e.value)
													}}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>}
										/>
									</div>
								</div>

								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('TARIFF_AMOUNT')}</p>
									<div className="col-md-4 p-0">
										<input type="text" className="form-control" name="tariff" {...register('tariff')} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('BALANCE')}</p>
									<div className="col-md-4 p-0">
										<input type="text" className="form-control" name="balance" {...register('balance')} />
									</div>
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							{getValues('id') ?
								<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/pos">{t('CANCEL')}</Link>
								:
								<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/admin/clients">{t('CANCEL')}</Link>
							}
							{ cityId &&
								<button type="submit" className="btn btn-success btn-rounded btn-wide btn-wide">{t('SAVE')}</button>
							}
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

