import React, { useState } from "react"
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GUESTPOST, GET } from 'helpers/api'
import { clearTemporaryStorage } from "helpers/helpers";

function Login() {
	const { t } = useTranslation();
	const history = useHistory();
	const [user, setUser] = useState({ "username": "", "password": "" })

	async function handleSubmit(e) {
		e.preventDefault();

		const response = await GUESTPOST('/auth/login', user)

		localStorage.setItem("username", user.username);
		localStorage.setItem('access_token', response.access_token)
		localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
		localStorage.setItem("authUser", JSON.stringify(user))

		const account = await GET('/services/uaa/api/account')
		localStorage.setItem("authorities", JSON.stringify(account.authorities));
		localStorage.setItem("role", account.authorities[0]);
		var checker = false
		var role = ""
		for (let i = 0; i < account.authorities.length; i++) {
			if (account.authorities[i] === "ROLE_ADMIN" || account.authorities[i] === "ROLE_INFO" || account.authorities[i] === "ROLE_MANAGER") {
				checker = true
				if (account.authorities[i] === "ROLE_ADMIN")
					role = "ROLE_ADMIN"
				if (account.authorities[i] === "ROLE_INFO")
					role = "ROLE_INFO"
				if (account.authorities[i] === "ROLE_MANAGER")
					role = "ROLE_MANAGER"
			}
		}

		if (checker === true) {

			if (role && role === "ROLE_ADMIN") {
				history.push("/dashboard")
			} else if (role && role === "ROLE_INFO") {
				history.push("/info-products")
			} else {
				history.push("/dashboard")
			}
		} else {
			toast.error(t('ERROR') + ' ' + t('YOU_DO_NOT_HAVE_ACCESS'))
			clearTemporaryStorage()
		}
	}

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">Вход в систему</h3>
									</div>
									<div className="p-2">
										<form className="form-horizontal" autoComplete="off" onSubmit={handleSubmit}>

											<input className="login-input"
												name="username"
												value={user.username}
												placeholder="Логин"
												onChange={(e) => setUser({ ...user, 'username': e.target.value })} />

											<input className="login-input"
												name="password"
												type="password"
												placeholder="Пароль"
												value={user.password}
												onChange={(e) => setUser({ ...user, 'password': e.target.value })} />

											<div className="text-center">
												<button type="submit" className="login-button">
													Вход
												</button>
											</div>
											{/* <div className="mt-3">
												<button
													className="btn btn-lg btn-success w-100"
													type="button"
													onClick={() => handleSubmit()}>
													<div className="d-flex justify-content-between">
														<div>Войти в систему</div>
														<div><i className="uil-arrow-right"></i></div>
													</div>
												</button>
											</div> */}

											<div className="mt-4 text-center">
												<Link to="/forgot-password" className="fw-medium text-white">Забыли пароль?</Link>
											</div>

										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default Login;
